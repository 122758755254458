@import '../abstracts/variables';

/* Material Customizations */
.mat-drawer-side {
  border-right: none;
}

.mat-drawer-inner-container {
  margin-left: 15px !important; /* adds space to the left of the category list */
  overflow: hidden !important; /* prevents the content section from scrolling */
}

.mat-sidenav-content {
  padding: 18px 18px 16px 18px;
  background-color: transparent;
  overflow: hidden !important; /* prevents the content section from scrolling */
}

.mat-drawer-container {
  background-color: transparent;
}

.mat-light-green {
  background-color: custom-palette('light-green');
  color: #444;
  &:hover {
    background-color: custom-palette('light-green-hover');
  }
}

.mat-light-red {
  background-color: custom-palette('light-red');
  color: rgba(0, 0, 0, 0.87);

  &:hover {
    background-color: custom-palette('light-red-hover');
  }
}

.mat-fab {
  width: 32px !important;
  height: 32px !important;
  line-height: 31px !important;
}

.mat-tooltip {
  font-size: 13px !important;
}

tr.mat-footer-row,
tr.mat-row {
  height: 32px !important;
}

tr.mat-header-row {
  height: 40px !important;
  background-color: #fafafa !important;
}

.mat-header-cell {
  color: #333;
}

.mat-sidenav {
  overflow: hidden;
}

.modal-footer-elapsed-time {
  margin-right: 20px;
  padding-top: 1px;
}

#preview-grid-container {
  margin-top: 40px;
}

.mat-snack-bar-container {
  margin-top: 100px !important;
}

.cdk-overlay-container {
  z-index: 9999;
}

.mat-menu-panel {
  max-width: 550px !important;
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
