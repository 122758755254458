@import '../abstracts/variables';

.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid custom-palette('valid-green');
}

.ng-invalid:not(form) {
  border-left: 5px solid custom-palette('error-red');
}

.error-text {
  color: custom-palette('error-red');
}

.field-error {
  border-left: 5px solid #a94442 !important;
}
