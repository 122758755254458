@import '../abstracts/variables';

* {
  font-family: $default-font-family;
}

html,
body {
  background-color: $page-background-color;
  font-size: $default-font-size;
  height: 100%;
  position: relative;
  margin: 0;
}

textarea {
  resize: vertical;
}

input {
  font-size: $default-font-size;
}

button {
  &:focus {
    outline: none;
  }
}

.f16 {
  font-size: 16px;
}
.f17 {
  font-size: 17px;
}
.f18 {
  font-size: 18px;
}

.preview-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.preview-spinner-message {
  width: max-content;
  margin-top: 14rem;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.preview-modal {
  min-height: 400px;
}
