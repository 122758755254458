@import '../abstracts/variables';

.monaco-editor {
  .scroll-decoration {
    box-shadow: none !important;
  }
  span {
    font-family: $monaco-editor-font-family !important;
  }
  .decorationsOverviewRuler {
    width: 0px !important;
  }
}

.editor-container {
  height: calc(100vh - 260px) !important;
}

.n1ql-editor-with-exceptions {
  .editor-container {
    height: calc(100% - 35px) !important;
  }

  ngx-monaco-editor {
    height: calc(100vh - 317px) !important;
    margin-bottom: -25px;
  }
}

.overflow-guard {
  border: 1px solid #dbdada;
}
