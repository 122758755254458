@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'base/base';
@import 'base/forms';
@import 'base/scrollbar';
@import 'overrides/primeNg';
@import 'overrides/material';
@import 'overrides/monacoEditor';
@import 'overrides/bootstrap';

.no-shadow {
  box-shadow: none;
}

.squared {
  border-radius: 5px;
}

.overflow-auto {
  overflow: auto;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.disabled-icon {
  color: lightgray;
}

.popout-editor ngx-monaco-editor {
  height: 60vh;
}

.top-slider-content {
  margin-top: 90px;
}

.charge-cat-grid-page {
  background-color: white;
  height: calc(100vh - 160px);
  padding: 10px 10px 0 10px;
}

.toast-success,
.toast-info,
.toast-warning,
.toast-error {
  width: 500px;
  max-width: 500px;
}

.cdm-table .p-datatable-scrollable {
  height: calc(100vh - 200px);
}

.toast-error,
.toast-warning,
.toast-info,
.toast-success {
  width: 500px;
  max-width: 500px;
}


.warn-text {
  color: #a98b42;
}