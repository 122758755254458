/* PrimeNG Customizations */
// .p-button:focus:not(.add-category-btn) {
//   box-shadow: none !important;
// }

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #64ccc9 !important;
  background: #64ccc9 !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #64ccc9 !important;
}

.p-checkbox .p-checkbox-box {
  &:hover {
    border-color: #64ccc9 !important;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #64ccc9 !important;
  background: #64ccc9 !important;
}

.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #008c95 !important;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: #008c95;
}

.p-inputtext:enabled {
  &:focus {
    box-shadow: 0 0 0 0.2rem #64ccc9 !important;
    border-color: #008c95 !important;
  }
  &:hover {
    border-color: #008c95 !important;
  }
}

.p-sidebar-top.p-sidebar-lg,
.p-sidebar-bottom.p-sidebar-lg {
  height: 91vh !important;
}

.p-multiselect {
  width: 100% !important;
}

.grid-tooltip .p-tooltip-text {
  width: fit-content;
  white-space: nowrap;
}

.p-autocomplete,
.p-autocomplete-multiple-container {
  width: 100% !important;
}

.p-datatable-loading-overlay {
  background-color: #b3b3b3;
  opacity: 0.2;
}

.cdmTableGearOverlay .p-listbox-list-wrapper {
  max-height: calc(100vh - 415px) !important;
}

.chargeDescTableGearOverlay .p-listbox-list-wrapper {
  max-height: 300px !important;
}

.manualTableGearOverlay .p-listbox-list-wrapper {
  max-height: 175px !important;
}

.p-datatable .p-datatable-loading-icon {
  font-size: 4rem;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #eaf9f8;
}

.p-overlaypanel {
  z-index: 3000 !important;
}

.profile-overlay.p-overlaypanel:after,
.profile-overlay.p-overlaypanel:before {
  left: unset !important;
  right: 0.9em !important;
}

.grid-menu-overlay.p-overlaypanel:after,
.grid-menu-overlay.p-overlaypanel:before {
  left: unset !important;
  right: 0.2em !important;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem 1rem 0.5rem 1rem !important;
}

.ui.tooltip-text {
  white-space: nowrap !important;
  width: fit-content !important;
}

.p-selectbutton .p-button {
  border: none !important;
  border-radius: 0px;
}

.p-selectbutton .p-button.p-highlight {
  background-color: #64ccc9 !important;
  background: none;
  &:focus {
    box-shadow: none;
  }
}

.table-filter-multiselect {
  height: 29px;
  line-height: 1;
}

.table-filter-multiselect-with-options {
  height: 29px;
  line-height: 1;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.radio-filter-btn-wrap .p-radiobutton-label {
  margin-bottom: 0 !important;
}

.cc-tab-wrapper .p-tabview-nav {
  background: none;
  border: none;
  margin-left: 5px;
  margin-top: -5px;
}

// the tab buttons are being cutoff by the nav-container.
.cc-tab-wrapper .p-tabview .p-tabview-nav-container {
  z-index: 1000;
}

.cc-tab-wrapper .p-tabview .p-tabview-nav .p-tabview-nav-link {
  padding: 0.75rem;
  border: none;
  border-radius: 3px;
  &:hover {
    text-decoration: none;
    background-color: #e4e4e4 !important;
  }
}

.cc-tab-wrapper .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #008c95;
}

.cc-tab-wrapper .p-tabview-panels {
  background: none;
}

.cc-tab-wrapper .p-tabview .p-tabview-nav li:hover {
  text-decoration: none;
  background-color: #e4e4e4 !important;
  cursor: pointer !important;
}

.cc-tab-wrapper .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #64ccc9 !important;
}

.cm-progress-bar .p-progressbar {
  width: 200px !important;
}

.cm-progress-bar .p-progressbar .p-progressbar-value {
  background: #64ccc9;
}

#categories .p-autocomplete-multiple-container {
  display: flow-root !important;
  overflow: auto;
  box-shadow: none;
}


.p-checkbox-disabled {
  background-color: gray;
}