/**
  FONTS
**/
$default-font-family: Arial, Helvetica, sans-serif;
$monaco-editor-font-family: 'Consolas';
$default-font-size: 15px;

/**
  COLOR PALETTES
**/
$green-primary: (
  'base': #74992e,
  'med': #467236,
  'light': #9aba43,
  'lighter': #d3e067
);
$blue-primary: (
  'base': #0c2444,
  'med': #173976,
  'light': #006eb7,
  'lighter': #3c9cd6
);
$orange-secondary: (
  'base': #fcaf17,
  'med': #f68a1e,
  'yellow': #f6e243
);
$purple-secondary: (
  'base': #772279,
  'hot-pink': #ab208e,
  'light-pink': #cf4b9b
);
$gray-secondary: (
  'base': #58595b,
  'light': #d1d2d4,
  'lighter': #eee
);
$custom-palette: (
  'light-red': #f5d7d7,
  'light-red-hover': #efc3c3,
  'lighter-red': #f5ecec,
  'light-green': #cdfae4,
  'light-green-hover': #b2f7d5,
  'lighter-green': #f7fff4,
  'error-red': #a94442,
  'valid-green': #42a948,
  'light-blue-gray': #e4e9f0
);

/**
  COLOR PALETTE FUNCTIONS
**/
@function green-primary($key: 'base') {
  @return map-get($green-primary, $key);
}
@function blue-primary($key: 'base') {
  @return map-get($blue-primary, $key);
}
@function orange-secondary($key: 'base') {
  @return map-get($orange-secondary, $key);
}
@function purple-secondary($key: 'base') {
  @return map-get($purple-secondary, $key);
}
@function gray-secondary($key: 'base') {
  @return map-get($gray-secondary, $key);
}
@function custom-palette($key: 'base') {
  @return map-get($custom-palette, $key);
}

/**
  PAGE COLORS
**/
$page-background-color: custom-palette('light-blue-gray');
