/* Bootstrap Customizations */

.navbar {
  z-index: 2000;
}

.nav-item:hover {
  cursor: pointer !important ;
}

.navbar-light .navbar-nav .nav-link {
  color: #333;
  font-size: 16px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.popover {
  width: 100% !important;
  word-break: break-all;
  margin-left: 50px;
  padding: 2px;
}

.popover-body {
  background-color: #555;
  color: #fff;
}

.btn-light {
  border-color: #e9ecef;
}

.btn-success {
  background-color: #64ccc9;
  border-color: transparent;

  &:hover {
    background-color: #008c95;
    border-color: transparent;
  }
  &:active {
    background-color: #008c95 !important;
    border-color: transparent;
  }
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #64ccc9;
  box-shadow: 0 0 0 0.2rem rgba(100, 204, 201, 0.25);
}

.cm-btn-warning {
  background-color: #fcf526;
  border-color: #dad427;
  &:hover {
    background-color: #fcf764;
    border-color: #dad427;
  }
}

.btn-toolbar {
  .btn {
    border: none;
  }
}

.radio-filter-btn-outline {
  border: 1px solid #ced4da;
  &:hover {
    background-color: #dcdcdc;
    color: white;
  }
}

@media (min-width: 510px) {
  .navbar {
    .main-nav li {
      margin-top: -34px;
      margin-bottom: -34px;
      a {
        margin-top: 17px !important;
      }
    }
  }
}
